<template>
  <div class="mx-4">
    <div class="items-center justify-center my-5">
      <h2
        class="text-center font-display text-3xl font-bold tracking-tight text-gray-700 md:text-4xl"
      >
        Our Sponsors
      </h2>
      <p class="text-center text-base text-primary font-semibold leading-7">
        We are grateful to every company that sponsored our company launch
        event.
      </p>
    </div>

    <div class="grid mx-10 my-10 gap-1 grid-cols-2 md:grid-cols-7">
      <span class="w-full flex items-center justify-center">
        <div aria-hidden="true">
          <img src="@/assets/coca.jpeg" />
        </div>
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/drivewise.jpeg" />
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/ethiolegal.jpeg" />
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/rsf.jpeg" />
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/secct.png" />
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/whizz.jpeg" />
      </span>
      <span class="w-full flex items-center justify-center">
        <img src="@/assets/abyss.jpeg" />
      </span>
    </div>
  </div>
</template>

<script >
export default {
  name: "spon-sors",
};
</script>

<style lang="scss" scoped>
</style>