<template>
  <div>
    <div
      class="bg-white sticky top-0 z-50 flex justify-between lg:py-2 items-center sm:px-1 w-screen"
    >
      <div class="logo mt-2">
        <div class="flex w-full items-start justify-center">
          <router-link to="/" class="items-center justify-center">
            <img src="@/assets/logooo.png" class="h-[5rem] pl-[3rem] mr-auto" />
            <p class="text-primary font-bold">
              Kena Menged Charity Organization
            </p>
          </router-link>
        </div>
      </div>
      <div class="sm:hidden">
        <button class="pr-10" @click="toggleNav()">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Menu / Menu_Alt_03">
              <path
                id="Vector"
                d="M5 17H13M5 12H19M5 7H13"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </button>
      </div>

      <div class="links mt-2 hidden sm:block pr-[3rem]">
        <ul
          class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
        >
          <li
            class="text-black font-semibold hover:text-primary"
            @click="scrollToHero"
          >
            Home
          </li>
          <li
            class="text-black font-semibold hover:text-primary"
            @click="scrollToAbout"
          >
            About
          </li>
          <li
            class="text-black font-semibold hover:text-primary"
            @click="scrollToRoadSafety"
          >
            Road Safety
          </li>
          <li
            class="text-black font-semibold hover:text-primary"
            @click="scrollToBoard"
          >
            Members
          </li>
          <li class="text-black hover:text-primary">
            <!-- <a :href="`tel:+251911477255`"> -->
            <router-link to="/contact">
              <button
                class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-xl"
              >
                Contact Us
              </button>
            </router-link>
            <!-- </a> -->
          </li>
        </ul>
      </div>

      <div
        class="bg-gray-800/40 fixed top-0 left-0 w-screen h-screen sm:hidden"
        @click="toggleNav()"
        v-if="showMobileNav"
      ></div>

      <div
        id="mobile-nav"
        class="bg-white w-[70vw] pt-4 pb-12 px-2 h-screen shadow-sm fixed right-0 top-0 sm:hidden"
        v-if="showMobileNav"
      >
        <span class="flex justify-between mb-12">
          <p class="font-bold text-gray-800">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Menu / Menu_Alt_03">
                <path
                  id="Vector"
                  d="M5 17H13M5 12H19M5 7H13"
                  stroke="#000000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </p>
          <button class="pr-10" @click="toggleNav()">back</button>
        </span>
        <section class="flex flex-col h-full justify-between">
          <div class="links items-start justify-start flex flex-col gap-4">
            <ul
              class="flex-col mt-8 space-y-4 ml-6 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
            >
              <li
                class="text-black items-start text-start hover:text-primary"
                @click="scrollToHero"
              >
                Home
              </li>
              <li
                class="text-black items-start text-start hover:text-primary"
                @click="scrollToAbout"
              >
                About
              </li>

              <li
                class="text-black items-start text-start hover:text-primary"
                @click="scrollToRoadSafety"
              >
                Road Safty
              </li>

              <li
                class="text-black items-start text-start hover:text-primary"
                @click="scrollToBoard"
              >
                Members
              </li>

              <li class="text-black items-start text-start hover:text-primary">
                <!-- <a :href="`tel:+251911477255`"> -->
                <router-link to="/contact">
                  <button
                    class="bg-yellow-500 hover:bg-primary text-white font-bold py-2 px-4 rounded-xl"
                  >
                    Contact Us
                  </button>
                </router-link>
                <!-- </a> -->
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <!-- <NavBar /> -->
    <HeroPage ref="hero" />
    <AboutUs ref="about" />

    <HighLight />
    <RoadSafety ref="roadSafety" />
    <PedestrianSafety />

    <AvoidRoadTips />
    <Sponsors />
    <Footer />
  </div>
</template>

<script>
import HeroPage from "@/components/HeroPage.vue";
// import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import AboutUs from "@/components/AboutUs.vue";
import HighLight from "@/components/HighLight.vue";
import RoadSafety from "@/components/RoadSafety.vue";
import PedestrianSafety from "@/components/PedestrianSafety.vue";
import AvoidRoadTips from "@/components/AvoidRoadTips.vue";
import Sponsors from "@/components/Sponsors.vue";
export default {
  name: "homeView",
  data() {
    return {
      showMobileNav: false,
    };
  },
  methods: {
    scrollToAbout() {
      this.$refs["about"].$el.scrollIntoView({ behavior: "smooth" });
      this.showMobileNav = !this.showMobileNav;
    },
    scrollToRoadSafety() {
      this.$refs["roadSafety"].$el.scrollIntoView({ behavior: "smooth" });
      this.showMobileNav = !this.showMobileNav;
    },
    scrollToHero() {
      this.$refs["hero"].$el.scrollIntoView({ behavior: "smooth" });
      this.showMobileNav = !this.showMobileNav;
    },
    toggleNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    scrollToBoard() {
      this.$router.push({ path: "/boardMembers" });
    },
  },
  components: {
    HeroPage,
    // NavBar,
    Footer,
    AboutUs,
    HighLight,
    RoadSafety,
    PedestrianSafety,
    AvoidRoadTips,
    Sponsors,
  },
};
</script>

<style lang="scss" scoped>
</style>