<template>
  <div>
    <section class="overflow-hidden bg-gray-200 py-8 sm:py-16">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div
          class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
        >
          <div class="lg:pr-8 lg:pt-4">
            <div class="lg:max-w-lg">
              <div class="pl-4 mb-10 border-l-4 border-primary">
                <h5
                  class="text-sm items-start justify-start text-bold text-start text-primary uppercase"
                >
                  Road Safety
                </h5>
                <h1
                  class="mt-2 text-3xl text-start font-black text-gray-700 md:text-3xl"
                >
                  Avoiding Drunk Driving and Substance Abuse
                </h1>
              </div>
              <p class="mt-6 text-lg text-start text-gray-600">
                Driving under the influence of alcohol or drugs is dangerous and
                illegal. Here's why you should avoid it:
              </p>
              <dl
                class="mt-10 max-w-xl space-y-8 text-base text-start text-gray-600 lg:max-w-none"
              >
                <div
                  class="relative pl-9"
                  v-for="(tip, index) in drivingTips"
                  :key="index"
                >
                  <dt class="inline font-semibold text-gray-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      class="absolute left-1 top-1 h-5 w-5 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14.5 10a4.5 4.5 0 004.284-5.882c-.105-.324-.51-.391-.752-.15L15.34 6.66a.454.454 0 01-.493.11 3.01 3.01 0 01-1.618-1.616.455.455 0 01.11-.494l2.694-2.692c.24-.241.174-.647-.15-.752a4.5 4.5 0 00-5.873 4.575c.055.873-.128 1.808-.8 2.368l-7.23 6.024a2.724 2.724 0 103.837 3.837l6.024-7.23c.56-.672 1.495-.855 2.368-.8.096.007.193.01.291.01zM5 16a1 1 0 11-2 0 1 1 0 012 0z"
                        clip-rule="evenodd"
                      ></path>
                      <path
                        d="M14.5 11.5c.173 0 .345-.007.514-.022l3.754 3.754a2.5 2.5 0 01-3.536 3.536l-4.41-4.41 2.172-2.607c.052-.063.147-.138.342-.196.202-.06.469-.087.777-.067.128.008.257.012.387.012zM6 4.586l2.33 2.33a.452.452 0 01-.08.09L6.8 8.214 4.586 6H3.309a.5.5 0 01-.447-.276l-1.7-3.402a.5.5 0 01.093-.577l.49-.49a.5.5 0 01.577-.094l3.402 1.7A.5.5 0 016 3.31v1.277z"
                      ></path></svg
                    >{{ tip.title }}
                  </dt>
                  <dd class="inline">
                    {{ tip.description }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <!-- <img
            src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw4fHxjb21wdXRlcnxlbnwwfDB8fHwxNjkxODE2NjY3fDA&ixlib=rb-4.0.3&q=80&w=1080"
            alt="Product screenshot"
            class="w-full h-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] pr-5"
          /> -->
          <div class="mr-0 md:mr-8 mb-6 md:mb-0">
            <img
              class="w-1/2 md:w-full mx-auto h-full"
              src="@/assets/image/srunk.jpeg"
              alt="can_help_banner"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drivingTips: [
        {
          title: "Impaired Judgment",
          description:
            "Alcohol and drugs impair your ability to make rational decisions and react quickly to changing road conditions.",
        },
        {
          title: "Reduced Coordination",
          description:
            " Impaired coordination makes it difficult to control your vehicle and respond to hazards.",
        },
        {
          title: "Slower Reaction Time",
          description:
            " Alcohol and drugs slow your reflexes, making it harder to avoid collisions.",
        },
        {
          title: "Alternative Transportation",
          description:
            " Use designated drivers, taxis, rideshare services, or public transportation when you're impaired.",
        },
        {
          title: "Designated Drivers",
          description:
            " If you're going out with friends, designate a sober driver before you start drinking.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>