<template>
  <div
    class="section max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6 bg-fixed bg-center bg-cover bg-no-repeat"
  >
    <div class="pl-4 ml-6 mb-10 border-l-4 border-primary">
      <h5
        class="text-sm items-start justify-start text-bold text-start text-primary uppercase"
      >
        Road Safety
      </h5>
      <h1 class="mt-2 text-3xl text-start font-black text-gray-100 md:text-5xl">
        Pedestrian Safety
      </h1>
    </div>

    <div class="flex flex-col md:flex-row">
      <!-- can help image -->
      <div class="mr-0 md:mr-8 mb-6 md:mb-0">
        <img
          class="w-1/2 md:w-full mx-auto h-full"
          src="@/assets/image/pedestrian.jpeg"
          alt="can_help_banner"
        />
      </div>
      <!-- end can help image -->

      <div class="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
        <div
          class="w-full sm:w-1/2 mb-4 px-2"
          v-for="(tip, index) in drivingTips"
          :key="index"
        >
          <div
            class="h-full py-4 px-6 border border-yellow-500 group relative inline-block overflow-hidden transition-all duration-200 group-hover:w-full border-t-0 border-l-0 rounded-br-xl"
          >
            <span
              class="ease absolute left-0 top-0 h-0 w-0 rounded-br-xl border-t-2 border-yellow-500 transition-all duration-200 group-hover:w-full"
            ></span>
            <span
              class="ease absolute bottom-0 left-0 h-0 w-0 rounded-br-xl border-l-2 border-yellow-500 transition-all duration-200 group-hover:h-full"
            ></span>
            <h3
              class="text-2xl text-emerald-300 text-start font-bold text-md mb-4"
            >
              {{ tip.title }}
            </h3>
            <p class="text-sm text-white text-start">
              {{ tip.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drivingTips: [
        {
          title: "Cross at Designated Crosswalks",
          description:
            "Use designated crosswalks and obey pedestrian signals when crossing the road",
        },
        {
          title: "Avoid Distractions",
          description:
            "Put away your phone and other distractions while crossing the road.",
        },
        {
          title: "Look Left-Right-Left",
          description:
            "Before crossing, look left, then right, then left again to ensure no vehicles are approaching.",
        },
        {
          title: "Avoid Jaywalking",
          description:
            "Cross the road at designated areas, even if it means walking a bit further.",
        },
        {
          title: "Be Cautious Around Parked Vehicles",
          description:
            "Watch out for vehicles that might suddenly pull out from parking spaces.",
        },
        {
          title: "Walk Against Traffic",
          description:
            " If there's no sidewalk, walk facing oncoming traffic so you can see vehicles approaching.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.section {
  background-image: linear-gradient(
      rgba(212, 231, 246, 0.2),
      rgba(23, 62, 78, 0.9)
    ),
    url(@/assets/image/safetyyy.jpeg);
}
</style>