<template>
  <div>
    <footer class="inset-0 bg-secondary mix-blend-multiply">
      <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div class="lg:flex lg:items-start lg:gap-8">
          <div
            class="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16"
          >
            <div class="col-span-3">
              <div>
                <h2 class="text-2xl text-start font-bold text-white">
                  Kena Menged
                </h2>

                <p class="mt-4 text-start text-white">
                  Kena Menged stands as a dynamic force committed to reshaping
                  the narrative of road safety in Ethiopia. With an unwavering
                  dedication to eliminating car collisions, our organization
                  envisions a nation where each journey is shielded, roads
                  become havens, and every life is valued. Through proactive
                  initiatives, education, and advocacy, Kena Menged strives to
                  instigate transformative change, fostering a safer and more
                  secure environment for communities.
                </p>
              </div>
            </div>

            <!-- <div class="col-span-2 sm:col-span-1"></div> -->

            <div class="col-span-3 sm:col-span-1">
              <p class="font-bold text-white">Company</p>

              <ul class="mt-6 space-y-4 text-sm">
                <li>
                  <router-link
                    to="/"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    About
                  </router-link>
                </li>

                <li>
                  <router-link
                    to="/contact"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    Contact
                  </router-link>
                </li>

                <li>
                  <router-link
                    to="/boardMembers"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    Member
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="col-span-3 sm:col-span-1">
              <p class="font-bold text-white">Helpful Links</p>

              <ul class="mt-6 space-y-4 text-sm">
                <li>
                  <router-link
                    to="/"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    Home
                  </router-link>
                </li>

                <li>
                  <router-link
                    to="/contact"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    Contact Us
                  </router-link>
                </li>

                <li>
                  <router-link
                    to="/termCondition"
                    class="text-gray-200 transition hover:opacity-75"
                  >
                    Term and Condition
                  </router-link>
                </li>
              </ul>
            </div>

            <ul
              class="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end"
            >
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100075877216318&mibextid=ZbWKwL"
                  rel="noreferrer"
                  target="_blank"
                  class="text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Facebook</span>

                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@KenaMenged-kb9kt?si=eW7YpQgmmf5unb5L"
                  class="text-white transition hover:opacity-75"
                >
                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                    ></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.tiktok.com/@kenamenged?_t=8giJsawcelJ&_r=1"
                  rel="noreferrer"
                  target="_blank"
                  class="text-white transition hover:opacity-75"
                >
                  <span class="sr-only">TikTok</span>

                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                    id="icons"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-8 border-t border-gray-100 pt-8">
          <div class="sm:flex sm:justify-between">
            <p class="text-xs text-gray-200">
              &copy; 2022. Kena Menged. All rights reserved.
            </p>

            <ul
              class="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end"
            >
              <li>
                <a href="#" class="text-gray-200 transition hover:opacity-75">
                  Terms & Conditions
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-100 transition hover:opacity-75">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-100 transition hover:opacity-75">
                  Cookies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "footer-view",
};
</script>

<style lang="scss" scoped>
</style>