<template>
  <div>
    <TipsSafeDriving />
  </div>
</template>

<script>
import TipsSafeDriving from "./TipsSafeDriving.vue";
export default {
  components: {
    TipsSafeDriving,
  },
};
</script>

<style lang="scss" scoped>
</style>