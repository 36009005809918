<template>
  <div class="bg-gray-200 justify-center w-full pt-10">
    <div class="items-center justify-center">
      <p class="text-center text-base text-primary font-semibold leading-7">
        Road Safety
      </p>
      <h2
        class="text-center font-display text-3xl font-bold tracking-tight text-gray-700 md:text-4xl"
      >
        Tips for Safe Driving
      </h2>
    </div>
    <div class="items-center flex flex-wrap p-8">
      <div
        class="w-full px-4 md:w-1/2 lg:w-1/3"
        v-for="(tip, index) in drivingTips"
        :key="index"
      >
        <div
          class="mb-9 bg-white border-10 group relative inline-block overflow-hidden border-gray-100 text-sm font-medium text-slate-800 focus:outline-none focus:ring active:border-secondary rounded-xl px-12 py-3 shadow-lg transition-all hover:shadow-xl sm:p-9 lg:px-6 xl:px-9"
        >
          <span
            class="ease absolute left-0 top-0 h-0 w-0 border-t-2 border-secondary transition-all duration-200 group-hover:w-full"
          ></span>
          <span
            class="ease absolute right-0 top-0 h-0 w-0 border-r-2 border-secondary transition-all duration-200 group-hover:h-full"
          ></span>
          <span
            class="ease absolute bottom-0 right-0 h-0 w-0 border-b-2 border-secondary transition-all duration-200 group-hover:w-full"
          ></span>
          <span
            class="ease absolute bottom-0 left-0 h-0 w-0 border-l-2 border-secondary transition-all duration-200 group-hover:h-full"
          ></span>
          <div class="mx-auto mb-7 items-start justify-start">
            <img :src="tip.iconSrc" :alt="tip.title" class="h-16 w-16" />
          </div>
          <div>
            <h3
              class="mb-4 text-xl text-start font-bold text-black sm:text-2xl lg:text-xl xl:text-2xl"
            >
              {{ tip.title }}
            </h3>
            <p class="text-start font-medium text-body-color">
              {{ tip.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      drivingTips: [
        {
          title: "Stay Alert",
          iconSrc: require("@/assets/image/alert.png"),
          description:
            "Pay full attention to your surroundings. Avoid distractions such as using your phone, eating, or grooming while driving.",
        },
        {
          title: "Following Distance Safety",
          iconSrc: require("@/assets/image/distance.png"),
          description:
            "Keep a safe distance from the vehicle in front of you to allow ample time to react to sudden changes.",
        },
        {
          title: "Obey Speed Limits",
          iconSrc: require("@/assets/image/road.png"),
          description:
            "Stick to posted speed limits and adjust your speed based on road and weather conditions",
        },
        {
          title: "Use Seatbelts",
          iconSrc: require("@/assets/image/safety.png"),
          description:
            "Always wear your seatbelt and ensure that all passengers, including children, are properly restrained",
        },
        {
          title: "Avoid Aggressive Driving",
          iconSrc: require("@/assets/image/acceleration.png"),
          description:
            "Stay calm, patient, and courteous on the road. Avoid aggressive behaviors like tailgating and road rage.",
        },
        {
          title: "Check Blind Spots",
          iconSrc: require("@/assets/image/blind-spot-monitor.png"),
          description:
            "Before changing lanes or making a turn, check your blind spots to ensure there are no vehicles, pedestrians, or cyclists.",
        },

        {
          title: "Follow Traffic Signals and Signs ",
          iconSrc: require("@/assets/image/traffic.png"),
          description:
            "Obey traffic lights, stop signs, and other road signs to ensure orderly and safe traffic flow.",
        },
        {
          title: "Avoid Driving Under the Influence",
          iconSrc: require("@/assets/image/car.png"),
          description:
            " Never drive under the influence of alcohol, drugs, or any substances that impair your ability to drive.",
        },
        {
          title: "Be Mindful of Weather Conditions",
          iconSrc: require("@/assets/image/air-conditioning.png"),
          description:
            " Adjust your driving technique during adverse weather conditions such as rain, snow, or fog.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>