<template>
  <div>
    <section
      class="relative h-[40rem] bg-fixed bg-center bg-cover bg-no-repeat"
    >
      <div
        class="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:flex lg:h-screen lg:py-[8rem] lg:px-8"
      >
        <div class="max-w-xl text-center">
          <h1 class="text-2xl w-full text-secondary font-extrabold sm:text-4xl">
            Road safety problems are

            <strong class="font-extrabold text-secondary"> local! </strong>
          </h1>

          <p class="mt-4 max-w-lg text-start text-white sm:text-xl/relaxed">
            Road safety is a critical concern that affects individuals,
            families, and entire communities. It encompasses a set of practices,
            rules, and guidelines aimed at minimizing the risk of collision,
            injuries, and fatalities on the road. By adhering to road safety
            principles, we can create a safer environment for all road users,
            including drivers, pedestrians, cyclists, and motorcyclists.
            <br />
          </p>

          <div class="mt-8 flex flex-wrap gap-4 text-center">
            <div
              class="block w-full bg-primary rounded-lg px-12 py-3 text-sm font-medium text-white shadow hover:bg-[#041697] focus:outline-none focus:ring active:bg-[#5c6ab1] sm:w-auto"
            >
              Learn More
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "landing-page",
};
</script>
  
  <style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.2),
      rgba(140, 163, 141, 0.9)
    ),
    url(@/assets/image/trafficlight-inline.webp);
}
</style>