<template>
  <section class="items-center bg-gray-200 pt-5 font-poppins">
    <div class="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
      <div class="flex flex-wrap">
        <div class="w-full mb-10 lg:w-1/2 lg:mb-0">
          <div class="relative lg:max-w-md">
            <img
              src="@/assets/image/aboutt.jpeg"
              alt="aboutimage"
              class="relative z-10 object-cover w-full rounded h-96"
            />
            <div
              class="absolute bottom-0 right-0 z-10 p-8 bg-white border-4 border-secondary rounded shadow lg:-mb-8 lg:-mr-11 sm:p-8"
            >
              <p class="text-lg font-semibold md:w-72">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="absolute top-0 left-0 w-16 p-4 h-16 text-primary"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"
                  ></path>
                </svg>
                Collision-Free Road, Guiding Every Journey Home!
              </p>
            </div>
          </div>
        </div>
        <div
          class="w-full px-6 items-start justify-start mb-10 lg:w-1/2 lg:mb-0"
        >
          <div class="pl-4 mb-6 border-l-4 border-primary">
            <h5
              class="text-sm items-start justify-start text-start text-primary uppercase"
            >
              Who we are?
            </h5>
            <h1
              class="mt-2 text-3xl text-start font-black text-gray-700 md:text-5xl"
            >
              About Us
            </h1>
          </div>
          <p class="mb-6 text-base text-start leading-7 text-gray-500">
            Kena Menged has established a dynamic and steadfast endeavor
            dedicated to eradicating car collision from the records. Our goal is
            to completely eliminate road collision, nurturing a nation where
            each journey is protected, every road is a haven, and every life is
            upheld.
            <br /><br />
            Kena Menged in Ethiopia stands for a transformative shift in the way
            we view road safety. Our name, "Kena Menged," encapsulates our
            aspiration and determination to bring about an Ethiopia where car
            collision are eliminated entirely.
          </p>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 items-center justify-center mt-10 gap-4 ml-5 mr-5 pb-9"
    >
      <!-- mission -->
      <div
        class="max-w-sm p-6 bg-white border border-secondary rounded-lg shadow lg:h-[15rem]"
      >
        <svg
          class="w-7 h-7 text-primary mb-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
          />
        </svg>
        <div class="items-center justify-start">
          <h5
            class="mb-2 text-2xl text-start font-bold tracking-tight text-secondary"
          >
            Mission
          </h5>
        </div>
        <p class="mb-3 font-normal text-start text-gray-500">
          Enhance road safety in Ethiopia by raising awareness, enforcing
          measures, and cultivating responsible behavior among all road users.
        </p>
      </div>
      <!-- vision -->

      <div
        class="max-w-sm p-6 bg-white border border-secondary rounded-lg shadow lg:h-[15rem]"
      >
        <svg
          class="w-7 h-7 text-primary mb-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
          />
        </svg>
        <div class="items-center">
          <h5
            class="mb-2 text-2xl text-start font-bold tracking-tight text-secondary"
          >
            Vision
          </h5>
        </div>
        <p class="mb-3 font-normal text-start text-gray-500">
          Creating a safer and responsible road environment for all in Ethiopia,
          where every journey is free from collision and loss of life.
        </p>
      </div>
      <!-- phylo -->

      <div
        class="max-w-sm p-6 bg-white border border-secondary rounded-lg shadow lg:h-[15rem]"
      >
        <svg
          class="w-7 h-7 text-primary mb-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
          />
        </svg>
        <div class="items-center justify-start">
          <h5
            class="mb-2 text-2xl text-start font-bold tracking-tight text-secondary"
          >
            Philosophy
          </h5>
        </div>
        <p class="mb-3 font-normal text-start text-gray-500">
          Driven by our vision, our road safety charity strives to enhance
          Ethiopian roads, save lives, and build a safer future through united
          efforts.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>